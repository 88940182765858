.Toastify__toast {
  box-shadow: 0 0px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.05);
  overflow: initial;
}

.Toastify__toast:hover .Toastify__close-button {
  color: #BBB;
}

.Toastify__toast-container {
  width: auto;
  max-width: 470px;
  z-index: 99999 !important;
}
.Toastify__toast-body {
  white-space: break-spaces;
}

.toasterv3 {
  background-color: #FFF;
  color: #444;
  border: 1px solid #CCC;
  border-radius: 8px !important;
  margin: 8px 4px;
  padding: 10px 20px !important;
  z-index: 99999 !important;
}


.toasterv3 .has-body {
  margin: 5px 3px;
  padding: 10px 8x !important;
  z-index: 99999 !important;
}

.Toastify__close-button {
  color: #EEE;
  position: relative;
  right: -9px;
  top: -5px;
} 

.toasterv3 h2 {
  font-size: .95em;
  margin: 0 12px 0 0;
  padding: 0;
  white-space: nowrap;
  color: dodgerblue;
  font-weight: normal;
}

.toasterv3 h2 .icon-cont {
  position: absolute;
  left: -15px;
  top: -8px;
  background-color: #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  margin: 0;
  color: #CCC;
}

.toasterv3 h2 .icon-cont svg {
  font-size: 28px;
}

.toasterv3 h2 .icon-cont svg.info, 
.toasterv3 h2 .icon-cont svg.error,
.toasterv3 h2 .icon-cont svg.success {
  display: none;
}
/* 
.toasterv3-info h2 .icon-cont svg.info {
  display: flex;
}

.toasterv3-error h2 .icon-cont svg.error {
  display: flex;
}

.toasterv3-success h2 .icon-cont svg.success {
  display: flex;
} */


.toasterv3 .has-body h2 {
  font-size: 1.05em;
  font-weight: bold;
}

.toasterv3-success h2 {
  color: #0bb10b;
}

.toasterv3-error h2 {
  color: red;
}

.toasterv3-warn h2 {
  color: #e07d05;
}

.toasterv3 .body {
  font-size: .9em;
  margin: 5px 0;
  padding: 2px 0;
  line-height: 1.6em;
  color: #777;
}

.toasterv3 .body a {
  color: #1489ff;
}

.toasterv3 .body a:hover {
  color: #087ef4;
  text-decoration: underline;
}

.toasterv3 .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 6px;
}

.toasterv3 .actions button {
  padding: 5px 10px;
  font-size: .8em;
  border: 0;
  box-shadow: none;
  border: 1px solid #CCC;
}
