html,
body {
  font-size: 16px;
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family:
    Poppins,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --colorPrimary: #2f2f33;
  --colorSecondary: #83838e;
  --cardHeight: 300px;
  --cardWidth: 270px;
  --cardRadius: 0.5rem;
}

body.high-contrast * {
  color: #333 !important;
}

body.high-contrast * .MuiButtonBase-root {
  opacity: 1 !important;
}

body.high-contrast #choose-plan-page-container * h1,
body.high-contrast #choose-plan-page-container * button *,
body.high-contrast #choose-plan-page-container * .name.first,
body.high-contrast #upload-list-btn *,
body.high-contrast #upload-list-btn-label *,
body.high-contrast .trigger *,
body.high-contrast .createButton-sc *,
body.high-contrast .fab-button-container * .MuiSvgIcon-root *,
body.high-contrast .export-transcript-button *,
body.high-contrast .PrivateValueLabel-label-16,
body.high-contrast .drag-and-drop-message-in-box *,
body.high-contrast .footer-options-for-uploader *,
body.high-contrast .upload-form-split-cont * {
  color: #fff !important;
}

body.high-contrast
  .upload-form-split-cont
  [class*='SelectLanguage__LanguageDefault-sc'] {
  opacity: 1;
}

body.dark * .body *,
body.dark * .play-controls *,
body.dark * .EditorV2__Main-sc-zxm2mu-1 * {
  background-color: #000;
  color: #fff;
  box-shadow: none !important;
}

body.dark * .FadeGradient__FadeLine-sc-1xdwrye-0,
body.dark * .transparent {
  background-color: none;
  opacity: 0;
}

.floating-link-popper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  background-color: white;
  border-radius: 4px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  font-size: 12px;
  max-width: 200px;
  z-index: 1;
}

.floating-link-popper a {
  color: dodgerblue;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

body.high-contrast div.UserAvatar--inner {
  color: #fff !important;
  background-color: #333 !important;
}

/*@media (max-width: 400px) {
  body.editor {
    transform: scale(.65, .7);
    transform-origin:  top left;
    width: 153%;
    height: 142%;
  }
}*/

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

.show-confidence .low-confidence {
  color: red;
}

.MuiTooltip-popper {
  z-index: 9999999;
}

.speaker-name {
  display: none;
}

.hide-speakers .speaker-cont {
  display: none;
}

.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translateX(0);
  backface-visibility: hidden;
}

.small-shake {
  animation: smallshake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translateX(0);
  backface-visibility: hidden;
}

.small-shake-delay {
  animation: smallshake 0.5s 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translateX(0);
  backface-visibility: hidden;
}

.pulsate {
  animation: pulsate 2s infinite;
}

.pulsate-less {
  animation: pulsateLess 2s infinite;
}

body.beacon-lifted .BeaconContainer {
  height: calc(100vh - 250px);
}

body.beacon-lifted div#beacon-container {
  z-index: 101;

  transform: translate(-5px, -120px) scale(1) !important;
}

body.beacon-lifted .acsb-trigger {
  inset: auto 60px 210px auto !important;
}

body.beacon-lifted div#beacon-container .Beacon div {
  background: none;
}

.flash,
.word.flash + .space {
  animation: wordFlash 2s;
  animation-timing-function: ease-in-out;
}

@media (min-width: 650px) and (min-height: 700px) {
  body.beacon-lifted div#beacon-container {
    z-index: 101;
    transform: translate(-5px, -120) scale(1) !important;
  }
}

@media (max-height: 740px) {
  :not(body.beacon-lifted) .acsb-trigger {
    inset: auto auto 57px 36px !important;
  }
}

.force-top-popper {
  z-index: 99999999 !important;
}

.language-select-listBox {
  max-height: 208px !important;
  margin-top: -50px;
}

.BeaconNotificationsFrame {
  z-index: 999;
}

@media (max-width: 624px) {
  .hide-small {
    display: none !important;
  }
}

@media print {
  .noprint {
    display: none;
  }
}

.spinning {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-2px);
  }

  20%,
  80% {
    transform: translateX(4px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-8px);
  }

  40%,
  60% {
    transform: translateX(8px);
  }
}

@keyframes smallshake {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-3px);
  }

  40%,
  60% {
    transform: translateX(3px);
  }
}

@keyframes wordFlash {
  0% {
    background-color: rgb(196 208 255 / 33%);
  }
  100% {
    background-color: rgb(196 208 255 / 0%);
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulsateLess {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
